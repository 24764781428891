import request from './request'

const api = {
	// 列表
	lists: '/BusinessCate/index',
	// 新增
	add: '/BusinessCate/create',
	// 删除
	dele: '/BusinessCate/delete',
	// 地区详情
	read: '/BusinessCate/read',
	// 编辑
	edit: '/BusinessCate/update',
}

export {
	request,
	api
}
